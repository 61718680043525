<template>
	<div class="goods_check">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="待审核" name="wait"></el-tab-pane>
			<el-tab-pane label="审核驳回" name="bh"></el-tab-pane>
			<el-tab-pane label="待寄样" name="djy"></el-tab-pane>
			<el-tab-pane label="已寄样" name="yjy"></el-tab-pane>
			<el-tab-pane label="已入库" name="yrk"></el-tab-pane>
			<el-tab-pane label="待排期" name="dpq"></el-tab-pane>
			<el-tab-pane label="待直播" name="dzb"></el-tab-pane>
			<el-tab-pane label="待退样" name="dty"></el-tab-pane>
			<el-tab-pane label="已退样" name="yty"></el-tab-pane>
			<el-tab-pane label="待完成" name="dwc"></el-tab-pane>
			<el-tab-pane label="已完成" name="ywj"></el-tab-pane>
			<el-tab-pane label="全部" name="all"></el-tab-pane>
		</el-tabs>
		<el-form :inline="true" ref="form" :model="form" label-width="auto" size="mini">
			<el-form-item label="商品名称">
				<el-input v-model="form.name" placeholder="请输入商品名称"></el-input>
			</el-form-item>
			<el-form-item label="店铺名称">
				<el-input v-model="form.shop_name" placeholder="请输入店铺名称"></el-input>
			</el-form-item>
			<el-form-item label="商品分类">
				<el-select v-model="form.cat" placeholder="商品分类">
					<el-option label="全部" value=""></el-option>
					<el-option :label="cate.name" :value="cate.id"  v-for="cate in cateLists" :key="cate.index"></el-option>
				</el-select>
			</el-form-item>
<!--			<el-form-item label="招商人">-->
<!--				<el-select v-model="form.region" placeholder="招商人">-->
<!--				<el-option label="区域一" value="shanghai"></el-option>-->
<!--				<el-option label="区域二" value="beijing"></el-option>-->
<!--				</el-select>-->
<!--			</el-form-item>-->
			<el-form-item label="报名日期">
				<el-date-picker
					v-model="form.baotime"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
					</el-date-picker>
			</el-form-item>
			<el-form-item label="排期日期">
				<el-date-picker
					v-model="form.pqtime"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
					</el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button @click="resetSearch()">重置</el-button>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="searchGoods()">查询</el-button>
			</el-form-item>
			<el-form-item>
				<el-button>导出</el-button>
			</el-form-item>
<!--			<el-form-item v-if="activeName === 'wait'">-->
<!--				<el-button>批量审核</el-button>-->
<!--			</el-form-item>-->
		</el-form>
		<div class="table_title flex_r bg_table mb10">
			<span>商品信息</span>
			<span>优惠方式/优惠金额</span>
			<span>福利</span>
			<span>试样</span>
			<span>操作</span>
			<span>排期</span>
		</div>
		<div class="br_1_eee" v-for="item in goodsLists" v-bind:key="item">
			<div class="item_title flex_r f_ai_c f_jc_b tc bg_table pl20 pr20 fs12">
				<div>
					<el-checkbox></el-checkbox>
					<span class="pl20 pr20">流水号：</span>
					<span v-if="item.shop_user_id">商品来源：商家报名</span>
					<span v-else>商品来源：自主提交</span>
				</div>
				<div>
					<img class="mr10" width="16" height="16" src="//img.alicdn.com/imgextra//cb/34/TB1nMIPiqL7gK0jSZFBwu0ZZpXa.png" alt="">
					<span>{{ item.nick }}</span>
				</div>
				<span>
					寄样信息：单号:<span style="color: red;">{{ item.kd_num }}</span>; &nbsp;地址: {{ item.addMobile }} - {{ item.addCountry }} - {{ item.addAddress }}
				</span>
				<span>
					状态：<span style="color: red;">{{ item.statusInfo }}({{ item.note }})</span>
				</span>
			</div>
			<div class="item_title bg_fff flex_r f_ai_c f_jc_b tc pl20 pr20 fs12 br_t_1_eee br_b_1_eee">
				<div>
					<span class="pr20">报名日期： {{ item.createtime }}</span>
					<span>意向佣金： {{ item.yx_rate }}%</span>
				</div>
				<div>
					<span class="pr20">招商： {{ item.username }}</span>
					<span>商家：{{ item.nick }}</span>
				</div>
			</div>
			<ul class="item_ul flex_r f_ai_c f_jc_b fs12">
				<li class="br_r_1_eee flex_r">
					<div class="br_r_1_eee pr10 mr10">
						<img height="80" width="80" :src="item.pict_url" alt="">
						<el-select @change="upCategoryOptions($event,item)" class="mt20" v-model="item.cid">
<!--							<el-option :label="item.name"></el-option>-->
							<el-option :label="cid.name" :value="cid.id"  v-for="cid in cateLists" v-bind:key="cid"></el-option>
						</el-select>
					</div>
					<div class="flex_c f_jc_b">
						<el-link class="lh_12" type="primary" :href="item.item_url" target="_blank">{{ item.title }}</el-link>
						<div>
							<span>在售价：</span>
							<span>{{ item.s_price }}</span>
						</div>
						<div>
							<span>直播价：</span>
							<span>{{ item.zb_price }}</span>
						</div>
						<div class="flex_r">
							<span class="w_50">规格：</span>
							<el-input type="textarea" :value="item.skuItem"></el-input>
						</div>
					</div>
				</li>
				<li class="br_r_1_eee flex_c f_jc_b">
					<div>
						<span class="cr_999">原价(划线价)：</span>
						<span>¥{{ item.s_price }}~{{ item.e_price }}</span>
					</div>
					<div>
						<span class="cr_999">在售价：</span>
						<span>¥{{ item.s_price }}~{{ item.e_price }}</span>
					</div>
					<div v-if="item.yh_type == 1">
						<span class="cr_999">-无：</span>
					</div>
					<div v-if="item.yh_type == 2">
						<span class="cr_999">-拍下立减：</span>
						<span>¥{{item.lj}}</span>
					</div>
					<div v-if="item.yh_type == 3">
						<span class="cr_999">-领券立减：</span>
						<span>¥{{item.lq_lj}}</span>
					</div>
					<div v-if="item.yh_type == 4">
						<span class="cr_999">-联系客服领取：</span>
						<span>¥{{item.kf_lj}}</span>
					</div>
					<div v-if="item.yh_type == 5">
						<span class="cr_999">-其他优惠：</span>
					</div>
					<div v-if="item.yh_type == 6">
						<span class="cr_999">-直接改价：</span>
					</div>
					<div>
						<span class="cr_999">=直播到手价：</span>
						<span>¥{{ item.zb_price }}~{{ item.zb_e_price }}</span>
					</div>
					<div>
						<span class="cr_999">历史最低价：</span>
						<span class="el-icon-s-data cr_ff0"></span>
					</div>
					<div class="tr">
						<el-link class="fs12" type="primary" @click="yh(item)">修改优惠</el-link>
					</div>
				</li>
				<li class="br_r_1_eee flex_c f_jc_b">
					<el-input :rows="3" type="textarea" @blur="zp(item)" placeholder="赠品：" v-model="item.zp" :value="item.zp"></el-input>
					<el-input :rows="3" type="textarea" @blur="zbj(item)" placeholder="抽奖：" v-model="item.zbj_cj" :value="item.zbj_cj"></el-input>
				</li>
				<li class="br_r_1_eee">
					<el-input :rows="6" @blur="sy(item)" type="textarea" v-model="item.sy" :value="item.sy" placeholder=""></el-input>
				</li>
				<li class="br_r_1_eee flex_c f_jc_a f_ai_c">
					<span class="cur_p" @click="$refs.dialogDataRef.handleOpen()">查看数据</span>
					<router-link tag="a" target="_blank" :to="{path:'/goodsDetail', query:{id:item.id}}">报名详情</router-link>
					<el-popover
							placement="top-start"
							title=""
							width="250"
							trigger="hover">
						<ul>
							<li class="flex_r f_jc_b mt2 mb2" v-if="item.c_id">
								<span v-if="item.c_status == 1">合同： 待签约</span>
								<span v-else-if="item.c_status == 2">合同： 已签约</span>
								<span v-else-if="item.c_status == 3">合同： 作废</span>
							</li>
							<li class="flex_r f_jc_b mt2 mb2" v-else>
								<span>合同： 待发起</span>
								<el-button @click="ht(item)" type="danger">发起</el-button>
							</li>
							<li class="flex_r f_jc_b mt2 mb2" v-if="item.e_id">
								<span v-if="item.e_status == 1">保证金： 待付款</span>
								<span v-else-if="item.e_status == 2">保证金： 已付款</span>
								<span v-else-if="item.e_status == 3">保证金： 已到账</span>
								<span v-else-if="item.e_status == 4">保证金： 已退还</span>
							</li>
							<li class="flex_r f_jc_b mt2 mb2" v-else>
								<span>保证金： 待发起</span>
								<el-button @click="bzj(item)" type="danger">发起</el-button>
							</li>
							<li class="flex_r f_jc_b mt2 mb2" v-if="item.f_id">
								<span v-if="item.f_status == 1">服务费： 待付款</span>
								<span v-else-if="item.f_status == 2">服务费： 已付款</span>
								<span v-else-if="item.f_status == 3">服务费： 已到账</span>
								<span v-else-if="item.f_status == 4">服务费： 已退还</span>
							</li>
							<li class="flex_r f_jc_b mt2 mb2" v-else>
								<span>服务费： 待发起</span>
								<el-button @click="fwf(item)" type="danger">发起</el-button>
							</li>
<!--							<li class="flex_r f_jc_b mt2 mb2">-->
<!--								<span>链接： 待提交</span>-->
<!--								<el-button @click="sponsorHandle(4)" type="danger">发起</el-button>-->
<!--							</li>-->
							<li class="flex_r f_jc_b mt2 mb2" v-if="item.status == 0">
								<span>样品： 待寄送</span>
								<el-button @click="jy(item)" type="danger">发起</el-button>
							</li>
							<li class="flex_r f_jc_b mt2 mb2" v-else>
							</li>
						</ul>
						<span slot="reference" class="cr_ff0 cur_p">交易服务</span>
					</el-popover>
					<router-link tag="a" target="_blank" :to="{path:'/goodsDetail', query:{id:item.id}}">备注</router-link>
				</li>
				<li class="br_r_1_eee">
					<div>
						<div class="pr5" style="    display: inline-block;">操作： </div>
						<div  style="    display: inline-block;width: 170px;">
							<el-select @change="getCategoryOptions($event,item)" :data-id="item.id">
								<el-option :label="ct.name" :value="ct.type" v-for="ct in item.ctype" v-bind:key="ct"></el-option>
								<!--						<el-option label="审核驳回" :value="sbh"></el-option>-->
								<!--						<el-option label="已入库" :value="rk"></el-option>-->
								<!--						<el-option label="增播" :value="zb"></el-option>-->
								<!--						<el-option label="销量录入" :value="lr"></el-option>-->
							</el-select>
						</div>
					</div>
					<div>
						<div class="pr5" style="    display: inline-block;">排期： </div>
						<div style="    display: inline-block;">
							<el-date-picker
									style="width:150px"
									@change="dataSelect($event,item)"
									class="mt10"
									v-model="item.zbtime"
									type="date"
									placeholder="选择排期">
							</el-date-picker>
						</div>
					</div>

					<div class="mt10 mb10" v-if="item.zbtime">
						<span class="cr_999">排期：</span>
						<span>{{ item.zbtime }}</span>
					</div>
					<div class="mt10 mb10" v-else>
						<span class="cr_999">排期：</span>
						<span>-</span>
					</div>
					<div v-if="item.act_id">
						<span class="cr_999">活动：</span>
						<span>{{ item.a_title }}</span>
					</div>
					<div v-else>
						<span class="cr_999">活动：</span>
						<span>-</span>
					</div>
				</li>
			</ul>
		</div>
		<el-pagination
			class="tr"
			background
			:small="false"
			@current-change="handleCurrentChange"
			:current-page.sync="currentPage"
			:page-size="pageSize"
			:total="total">
		</el-pagination>
		<e-dialog title="选择优惠" ref="dialogRef" v-on:confirm="yhConfirm">
			<span>优惠方式：</span>
			<el-select v-model="yh_type">
				<el-option label="无" value="1"></el-option>
				<el-option label="拍下立减" value="2"></el-option>
				<el-option label="详情页领券" value="3"></el-option>
				<el-option label="联系客服领取" value="4"></el-option>
				<el-option label="其他优惠" value="5"></el-option>
				<el-option label="直接改价" value="6"></el-option>
			</el-select>
<!--			<el-form-item label="名称">-->
<!--				<el-input v-model="form.name" placeholder="请输入商品名称/店铺名称"></el-input>-->
<!--			</el-form-item>-->
			<el-input v-model="lj" placeholder="优惠金额" v-if="yh_type == 2"></el-input>
			<el-input v-model="lq_lj" placeholder="优惠金额" v-if="yh_type == 3"></el-input>
			<el-input v-model="kf_lj" placeholder="优惠金额" v-if="yh_type == 4"></el-input>
		</e-dialog>
		<e-dialog width="1000px" title="查看数据" ref="dialogDataRef">
			<Empty />
		</e-dialog>
		<e-dialog :title="dialogTitle" ref="dialogSponsorRef" v-on:confirm="confirm">
			<e-form v-on:selectChange="selectChange" ref="formRef" :formConfList="formConfListItem" :formData="formData">
				<template #1>
					<el-button @click="$router.push('/address')" type="primary">新增地址</el-button>
				</template>
			</e-form>
		</e-dialog>
	</div>
</template>

<script>
import {businessTableConf, searchFormList} from '@data/business'
import {formConfList} from '@data/handCard'
export default {
	name: 'Goods',
	data() {
		return {
			form: {},
			businessTableConf,
			searchFormList,
			formConfList,
			formConfListItem: [],
			dataOrigin: {
				data: [
					{
						account1: '一条网络科技有限公司',
						value: 3,
						tag: '品牌方'
					}
				]
			},
			activeName: "wait",
			currentPage: 1,
			pageSize: 10,
			total: 0,
			dialogTitle: '',
			goodsLists: [],
			cateLists: [],
			formData: {},
			coptions: "",
			conItem: {},
			conType: "",
			pqtime: '',
			addressLists: [],
			yh_type: '',
			kf_lj: '',
			lj: '',
			lq_lj: ''
		}
	},
	created() {

		if(this.$route.query.activeName){
			this.activeName = this.$route.query.activeName
		}
		this.GetEntrLists()
	},
	methods: {
		zp(item){
			let that = this
			if(item.zp == ''){
				return
			}
			this.post('?server=live.zp.push.handle.post',{goods_id:item.id,zp:item.zp}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.GetEntrLists()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		zbj(item){
			let that = this
			if(item.zbj_cj == ''){
				return
			}
			this.post('?server=live.cj.push.handle.post',{goods_id:item.id,cj:item.zbj_cj}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.GetEntrLists()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		sy(item){
			let that = this
			this.post('?server=live.anchor.sy.push.handle.post',{goods_id:item.id,sy:item.sy}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.GetEntrLists()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		selectChange(e){
			console.log(e)
			let addressLists = this.addressLists
			let len = addressLists.length
			for(let i=0;i<len;i++){
				// console.log(response.data.data[i])
				if(e == addressLists[i].id){
					this.$refs.formRef.formData.username = addressLists[i].username
					this.$refs.formRef.formData.address = addressLists[i].name
					this.$refs.formRef.formData.mobile = addressLists[i].mobile
				}
			}
		},
		dataSelect (e,value){
			// console.log(value)
			let that = this
			if(e == ''){
				this.$message.error("请选择排期时间");
				return
			}
			this.post('?server=live.schedule.push.handle.post',{goods_id:value.id,date:e}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.pqtime = ''
				that.GetEntrLists()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		upCategoryOptions(e,item){
			let that = this
			this.get('?server=live.category.push.handle.post',{goods_id:item.id,cid:e}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.pqtime = ''
				that.GetEntrLists()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		getCategoryOptions(e,item){
			switch (e) {
				case "jy":
					this.conType = "jy"
					this.jy(item)
					this.conItem = item
					break;
				case "rk":
					this.conType = "rk"
					this.rk(item)
					this.conItem = item
					break;
				case "zb":
					this.conType = "zb"
					this.zb(item)
					this.conItem = item
					break;
				case "sbh":
					this.conType = "sbh"
					this.sbh(item)
					this.conItem = item
					break;
				case "lr":
					this.conType = "lr"
					this.lr(item)
					this.conItem = item
					break;
				case "hj":
					this.conType = "hj"
					this.hj(item)
					this.conItem = item
					break;
			}
		},
		jy(e){
			console.log(e)
			this.conType = "jy"
			this.conItem = e
			this.sponsorHandle(5)

		},
		ht(e){
			console.log(e)
			this.conType = "ht"
			this.conItem = e
			this.sponsorHandle(1)

		},
		hj(e){
			console.log(e)
			this.conType = "hj"
			this.conItem = e
			this.sponsorHandle(8)

		},
		bzj(e){
			console.log(e)
			this.conType = "bzj"
			this.conItem = e
			this.sponsorHandle(2)
		},
		fwf(e){
			console.log(e)
			this.conType = "fwf"
			this.conItem = e
			this.sponsorHandle(3)
		},
		yh(e){
			console.log(e)
			this.conType = "fwf"
			this.conItem = e
			this.yh_type = e.yh_type
			this.lj = e.lj
			this.lq_lj = e.lq_lj
			this.kf_lj = e.kf_lj
			this.$refs.dialogRef.handleOpen()
		},
		sbh(e){
			console.log(e)
			this.sponsorHandle(6)
			// this.post('?server=live.bh.push.handle.post',{id:1,memo:1}).
			// then(function (response) {
			// 	//这里只会在接口是成功状态返回
			// 	//联网成功的回调
			// 	console.log(response)
			// }).catch(function (error) {
			// 	//这里只会在接口是失败状态返回，不需要去处理错误提示
			// 	console.log(error);
			// });
		},
		yhConfirm(){
			let that = this
			this.post('?server=live.price.push.handle.post',{goods_id:this.conItem.id,yh_type:this.yh_type,lj:this.lj,lq_lj:this.lq_lj,kf_lj:this.kf_lj}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				console.log(response)
				that.$refs.dialogRef.handleClose()
				that.GetEntrLists()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		confirm(){
			let that = this
			let formData = this.$refs.formRef.formData
			switch (this.conType) {
				case "jy":
					if(formData.addressLists == '' || formData.addressLists == undefined){
						that.$message.error('请选择收货地址');
						return
					}
					this.post('?server=live.jy.push.handle.post',{require:formData.require,address_id:formData.addressLists,goods_id:this.conItem.id}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.$message.success(response.data.msg);
						console.log(response)
						that.$refs.dialogSponsorRef.handleClose()
						that.GetEntrLists()
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break;
				case "sbh":

					if(formData.memo == '' || formData.memo == undefined){
						this.$message.error("请填写驳回备注");
						return
					}
					this.post('?server=live.bh.push.handle.post',{goods_id:this.conItem.id,memo:formData.memo}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.$message.success(response.data.msg);
						console.log(response)
						that.$refs.dialogSponsorRef.handleClose()
						that.GetEntrLists()
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break;
				case "lr":

					if(formData.cjbs == '' || formData.cjbs == undefined){
						this.$message.error("请填写成交笔数");
						return
					}
					if(formData.cjs == '' || formData.cjs == undefined){
						this.$message.error("请填写成交数量");
						return
					}
					if(formData.xse == '' || formData.xse == undefined){
						this.$message.error("请填写销售总额");
						return
					}
					if(formData.type == '' || formData.type == undefined){
						this.$message.error("请选择直播状态");
						return
					}
					this.post('?server=live.data.push.handle.post',{goods_id:this.conItem.id,cjs:formData.cjs,cjbs:formData.cjbs,cje:formData.xse,type:formData.type}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.$message.success(response.data.msg);
						console.log(response)
						that.$refs.formRef.formData = {}
						that.$refs.dialogSponsorRef.handleClose()
						that.GetEntrLists()
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break;
				case "ht":

					if(formData.contract == '' || formData.contract == undefined){
						this.$message.error("请选择合同");
						return
					}
					if(formData.pay == '' || formData.pay == undefined){
						this.$message.error("请选择收款账户");
						return
					}
					if(formData.bzj == '' || formData.bzj == undefined){
						this.$message.error("请填写保证金");
						return
					}
					if(formData.service == '' || formData.service == undefined){
						this.$message.error("请填写服务费");
						return
					}
					this.post('?server=live.contract.push.handle.post',{goods_id:this.conItem.id,contract_id:formData.contract,pay_id:formData.pay,bzj:formData.bzj,fwf:formData.service}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.$message.success(response.data.msg);
						console.log(response)
						that.$refs.formRef.formData = {}
						that.$refs.dialogSponsorRef.handleClose()
						that.GetEntrLists()
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break;
				case "fwf":

					if(formData.pay == '' || formData.pay == undefined){
						this.$message.error("请选择收款账户");
						return
					}
					if(formData.fwf == '' || formData.fwf == undefined){
						this.$message.error("请填写服务费");
						return
					}
					this.post('?server=live.service.fee.push.handle.post',{goods_id:this.conItem.id,pay_id:formData.pay,fwf:formData.fwf}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.$message.success(response.data.msg);
						console.log(response)
						that.$refs.formRef.formData = {}
						that.$refs.dialogSponsorRef.handleClose()
						that.GetEntrLists()
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break;
				case "bzj":

					if(formData.pay == '' || formData.pay == undefined){
						this.$message.error("请选择收款账户");
						return
					}
					if(formData.bzj == '' || formData.bzj == undefined){
						this.$message.error("请填写保证金");
						return
					}
					this.post('?server=live.bzj.fee.push.handle.post',{goods_id:this.conItem.id,pay_id:formData.pay,bzj:formData.bzj}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.$message.success(response.data.msg);
						console.log(response)
						that.$refs.formRef.formData = {}
						that.$refs.dialogSponsorRef.handleClose()
						that.GetEntrLists()
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break;
				case "hj":
					this.post('?server=live.hj.push.handle.post',{dh:formData.dh,goods_id:this.conItem.id}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.$message.success(response.data.msg);
						console.log(response)
						that.$refs.dialogSponsorRef.handleClose()
						that.GetEntrLists()
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break;
			}
		},
		rk(e){
			console.log(e)
			let that = this
			this.post('?server=live.rk.push.handle.post',{goods_id:e.id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				console.log(response)
				that.GetEntrLists()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		zb(e){
			let that = this
			this.post('?server=live.zb.push.handle.post',{goods_id:e.id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				console.log(response)
				that.GetEntrLists()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		lr(e){
			console.log(e)
			this.sponsorHandle(7)
		},
		resetSearch(){
			this.form = {}
			this.currentPage = 1
			this.GetEntrLists()
		},
		searchGoods() {
			this.currentPage = 1
			this.GetEntrLists()
		},
		handleCurrentChange(val){
			this.currentPage = val
			this.GetEntrLists()
		},
		dateToMs(date) {
			let res = new Date(date).getTime();
			return parseInt(res/1000)
		},
		GetEntrLists(){
			let that = this
			this.goodsLists = []
			this.get('?server=live.entr.goods.lists.get',{type:this.activeName,
				page:this.currentPage,
				query:this.form.name,
				shop_name:this.form.shop_name,
				cat:this.form.cat,
				baotime:this.form.baotime,pqtime:this.form.pqtime}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				that.goodsLists = response.data.lists.data
				that.total = response.data.lists.total
				that.cateLists = response.data.category
				console.log(that.goodsLists)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		handleClick() {
			this.currentPage = 1
			this.form = {}
			this.GetEntrLists()
		},
		dialogOpen() {
			this.$refs.dialogSponsorRef.handleOpen();
		},
		sponsorHandle(val) {
			let that = this
			switch(val) {
				case 1:
					this.dialogTitle = '通知商家签约';
					this.get('?server=live.contract.start.info.get',{id:this.conItem.id,cid:this.conItem.cid}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.formData = response.data
						that.formConfListItem = [
							{
								label: '合同模板',
								prop: 'contract',
								type: 'select',
								options: response.data.contract
							},
							{
								label: '保证金',
								prop: 'bzj',
								type: 'input'
							},
							{
								label: '服务费',
								prop: 'service',
								type: 'input'
							},
							{
								label: '收款信息',
								prop: 'pay',
								type: 'select',
								options: response.data.pay
							}
						];
						that.$refs.formRef.formData.bzj = response.data.bzj
						that.$refs.formRef.formData.service = response.data.service
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break
				case 2:
					this.dialogTitle = '新增保证金';
					this.get('?server=live.bzj.info.get',{id:this.conItem.id,cid:this.conItem.cid}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.formData = response.data
						that.formConfListItem = [
							{
								label: '保证金金额',
								prop: 'bzj',
								type: 'input'
							},
							{
								label: '收款信息',
								prop: 'pay',
								type: 'select',
								options: response.data.pay
							}
						];
						that.$refs.formRef.formData.bzj = response.data.bzj
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break
				case 3:
					this.dialogTitle = '新增服务费';
					this.get('?server=live.fwf.info.get',{id:this.conItem.id,cid:this.conItem.cid}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.formData = response.data
						that.formConfListItem = [
							{
								label: '服务费金额',
								prop: 'fwf',
								type: 'input'
							},
							{
								label: '收款信息',
								prop: 'pay',
								type: 'select',
								options: response.data.pay
							}
						];
						that.$refs.formRef.formData.fwf = response.data.service
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break
				case 4:
					this.dialogTitle = '佣金链接';
					break
				case 5:
					this.dialogTitle = '通知寄样';
					that.formConfListItem = []
					this.post('?server=live.jy.info.get',{}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						console.log(response)
						that.addressLists = response.data
						that.formConfListItem = [
							{
								label: '收货地址',
								prop: 'addressLists',
								type: 'select',
								options: response.data
							},
							{
								label: '新增地址',
								prop: '1',
								type: 'solt'
							},
							{
								label: '收件人',
								prop: 'username',
								type: 'input',
								disabled: true
							},
							{
								label: '收件地址',
								prop: 'address',
								type: 'input',
								disabled: true
							},
							{
								label: '联系电话',
								prop: 'mobile',
								type: 'input',
								disabled: true
							},
							{
								label: '寄样要求',
								prop: 'require',
								type: 'input'
							},
						]
						let len = that.addressLists.length
						for(let i=0;i<len;i++){
							// console.log(response.data.data[i])
							if(that.addressLists[i].is_default == 1){
								that.$refs.formRef.formData.addressLists = that.addressLists[i].id
								that.$refs.formRef.formData.username = that.addressLists[i].username
								that.$refs.formRef.formData.address = that.addressLists[i].name
								that.$refs.formRef.formData.mobile = that.addressLists[i].mobile
							}
						}
						// that.$refs.formRef.formData.addressLists = response.data
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break
				case 6:
					this.dialogTitle = '备注';
					break
			}
			this.flag = false;
			this.formConfListItem = this.formConfList[val]; 
			this.dialogOpen();
		}
	}
}
</script>

<style lang="scss" scoped>
.w140 {
	width: 140px;	
}
.table_title {
	span {
		line-height: 40px;
		width: 15%;
		text-align: center;
		&:nth-child(1) {
			width: 25%;
		}
	}
}
.item_title {
	line-height: 30px;
}
.item_ul {
	li {
		width: 15%;
		padding: 10px;
		height: 140px;
		&:nth-child(1) {
			width: 25%;
		}
	}
}
</style>